// Green
$green: #223e07;
$light_green: #699f35;
$gradient: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
$dark_green: #477b14;

// Grey
$grey: #454545;
$grey-bg: #f9f9f9;
$grey-light: #a3a2a2;

// Black
$black: #000;

// White
$white: #fff;

// Header

// $header_gradient: linear-gradient(
//   180deg,
//   rgba(117, 117, 117, 0.2) 0%,
//   rgba(250, 250, 250, 0.2) 100%
// );
$header_gradient: linear-gradient(
  to bottom,
  rgba(159, 159, 159, 1) 0,
  rgba(0, 0, 0, 0) 100%
);

$header_active: rgba(255, 255, 255, 0.8);

$transition: all 0.3s linear;
$spacing: 0.1rem;
