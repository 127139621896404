@import '../../vars';

.header-container {
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

header {
  display: flex;
  width: 100%;
  height: 100%;
}
.header {
  width: 100%;
  height: 85px;
  display: flex;

  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  color: $black;

  background: $header_gradient;
}

.header.dark {
  background-image: linear-gradient(
    to bottom,
    rgba(223, 223, 223, 1) 0%,
    rgba(223, 223, 223, 0.95) 100%
  );
}

.header.active {
  background-image: $header_active;
}

.header_inner {
  display: flex;
  width: 100%;
  height: 85px;
  justify-content: space-between;
  align-items: center;
}

.index {
  justify-content: center;
}

.beresneva-logo {
  display: block;
  max-width: 250px;
  height: auto;
}
.nav {
  display: flex;
  height: 100%;
  font-size: 15px;
}

.nav_link {
  width: 100%;
  min-width: 100px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  text-decoration: none;

  color: $black;

  transition: all 0.2s linear;

  &.active {
    color: $dark_green;
  }
}

.nav_link:not(.active):hover {
  color: $dark_green;
}

.link-text {
  position: relative;
  &::before {
    content: '';
    height: 2px;
    border-radius: 1px;
    width: 0px;
    background-color: $black;

    position: absolute;
    bottom: 0;
    left: 0;
    transition: all 0.3s ease;
  }
}

.link-text:not(.active):hover {
  &::before {
    width: 100%;
    background-color: $dark_green;
  }
}

.media {
  width: 150px;
  height: 25px;
}

.media--hide {
  width: 150px;
  height: 25px;
  color: transparent;
}

.media_link {
  width: 100%;
  padding-right: 20px;

  text-decoration: none;
}

.media_link:last-child {
  padding-right: 0;
}

@media screen and (max-width: 800px) {
  .header {
    display: none;
  }
}
