@import '../../vars';

.author {
  display: flex;
}

.author-item {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;

  &.photo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
  }

  .author-title {
    font-size: 32px;
  }

  .author-suptitle {
    font-size: 18px;
  }

  .author-text {
    font-family: Montserrat;
    font-size: 18px;
    width: 80%;
  }

  .author-list {
    &-title {
      font-size: 24px;
    }

    font-family: Montserrat;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 80%;

    .list-content {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding-left: 20px;

      li {
        list-style-type: disc;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .author {
    flex-direction: column;
    gap: 30px;
  }

  .author-item {
    order: 2;
    gap: 20px;
  }

  .author-item.photo {
    order: 1;
    width: 100%;
  }
}
