@import '../../vars.scss';

.single-project {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 50px;

  &-subcontent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 40%;
  }

  &-slider {
    width: 60%;

    img {
      width: 100%;
      height: auto;
    }
  }

  &-content {
    font-family: 'Exo 2', sans-serif;
    font-size: 18px;

    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.project-link {
  display: flex;
  justify-content: start;
}

.project-return {
  position: relative;

  &:after {
    content: '';
    width: 15px;
    height: 2px;
    border-radius: 1px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: $black;
    transition: all 0.3s ease-out;
  }

  &:hover {
    color: $green;
    &:after {
      width: 100%;
      background-color: $green;
    }
  }
}

@media screen and (max-width: 800px) {
  .single-project-subcontent {
    flex-direction: column;
    gap: 20px;
  }

  .single-project-info {
    width: 100%;
    order: 2;
  }

  .single-project-slider {
    width: 100%;
    order: 1;
  }

  .single-project-info h1 {
    font-size: 24px;
  }

  .project-items-title {
    font-size: 18px;
  }
}
