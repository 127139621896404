@import '../../vars';

.container {
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.publications {
  display: flex;
  width: 100%;
}

.publications__list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 25px 25px;
}

.items__body {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  gap: 20px;
}

.item__video {
  position: relative;
  margin: 0 0 5px 0;
  /* aspect-ratio: 16 / 9; */
  padding: 0 0 57% 0;
}

.item__video video,
.item__video iframe {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.projects__item__bottom {
  display: block;

  position: relative;
}

.projects__item__title {
  font-size: 22px;
  font-weight: 400;
}

.projects__item__suptitle {
  font-size: 17px;
  font-weight: 400;
}
