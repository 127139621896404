@import 'vars.scss';

@font-face {
  font-family: 'Arkhip';
  src: url('./assets/fonts/Archip.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AgencyFB';
  src: url('./assets/fonts/AgencyFB.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html,
body {
  height: 100%;
  position: relative;
}

body {
  font-family: 'Arkhip';
  font-size: 18px;
  line-height: 1.5;
  margin: 0;
  color: $black;
  background-color: $grey-bg;

  display: block;
}

body.no-scroll,
body.show-nav {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  text-decoration: none;
}

ul,
ol,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: $black;
}

span {
  color: $light_green;
}

p {
  margin: 0 0 10px;
}

*::-webkit-scrollbar {
  width: 0.35rem;
  height: 0.35rem;
}

*::-webkit-scrollbar-track {
  box-shadow: insert 0 0 6px rgba(0, 0, 0, 0);
}

*::-webkit-scrollbar-thumb {
  background-color: $grey;
  border-radius: 6px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: $dark-green;
}
