.error-container {
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;

  .error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .error-img {
    width: 90vw;
    max-width: 600px;
    display: block;
    margin-bottom: 2rem;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  .error-title {
    margin-bottom: 0.5rem;
  }
  .error-text {
    margin-top: 0;
    margin-bottom: 0.5rem;
    color: black;
  }
  .error-link {
    color: black;
    text-decoration: underline;
    text-transform: capitalize;
  }
}
