@import '../../vars';

.footer {
  margin-top: auto;
  padding: 10px 0;

  background-color: #a3a2a2;
}

.footer__content {
  display: flex;
  justify-content: space-between;
  line-height: 90px;
}

.copyright {
  display: flex;
  font-size: 16px;
}

.copyright__symvol {
  font-size: 15px;
}

.footer__contacts {
  display: flex;
  gap: 30px;

  font-size: 17px;
  font-weight: 500;
  line-height: 90px;
  font-style: normal;
}

.mobile-link {
  position: relative;
}

.mobile-link::before {
  content: '';
  height: 2px;
  border-radius: 1px;
  width: 0;
  background-color: #000;
  position: absolute;
  left: 0;
  bottom: 0;

  transition: all 0.3s linear;
}
.mobile-link:hover {
  &::before {
    width: 100%;
  }
}

.media__link {
  padding-right: 30px;
}

.media__link:last-child {
  padding-right: 0;
}

@media screen and (max-width: 800px) {
  .footer {
    padding: 20px 0;
  }

  .footer__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1;
  }
}
