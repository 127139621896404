.sert-items {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  justify-content: space-around;
}

.sert-item {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 370px;
  scale: 1;
  transition: all 0.3s linear;
  &:hover {
    scale: 1.02;
    cursor: pointer;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

.sert-item-full-size {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  cursor: pointer;
}

.close-button {
  position: absolute;
  top: -30px;
  right: -30px;
  background: none;
  border: none;
  cursor: pointer;
  transition: transform 0.3s;
}

.close-button:hover {
  transform: rotate(90deg);
  transition: transform 0.3s;
}

.sert-item-container {
  position: relative;
  cursor: default;
  max-width: 70%;
  max-height: 70%;
}

.sert-item-container img {
  max-width: 100%;
  max-height: 80vh;
}
