@import '../../vars.scss';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 400px;

  position: relative;
}

.close-button {
  position: absolute;
  top: -30px;
  right: -30px;
  background: none;
  border: none;
  cursor: pointer;
  transition: transform 0.3s;
}

.title-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-title {
  margin-bottom: 10px;
  font-family: inherit;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
}

.modal-text {
  font-family: inherit;
  font-size: 14px;
}

.modal-form {
  display: flex;
  gap: 20px;
}

.modal-content form {
  display: flex;
  flex-direction: column;
}

.form-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;

  font-family: inherit;
  font-size: 24px;

  background-color: $dark-green;
  border: none;
  border-radius: 10px;

  cursor: pointer;
}

.suptitle-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #707070;
}

.modal-suptitile {
  font-size: 18px;
}
