.input-container {
  position: relative;
}

.input-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  img {
    width: 100%;
    height: 100%;
  }
}

.input-field {
  display: block;
  width: 100%;
  font-size: 15px;
  padding-bottom: 12px;
  padding-left: 40px;
  background: none;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 0;
  appearance: none;
  box-shadow: none;
  color: #2c2c2c;
  font-family: 'Exo 2', sans-serif;
  font-weight: 500;
  outline: none;
  transition: border-color 0.2s linear;
}
