@import '../../vars.scss';

.project {
  display: flex;
}

.project.reverse {
  flex-direction: row-reverse;
}

.project-item {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 50%;
  &.text-info {
    padding: 20px;
  }
}

.project-title {
  font-size: 32px;
}

.project-year {
  font-family: Montserrat;
  font-size: 24px;
}

.project-title.right,
.project-year.right {
  text-align: right;
}

.project-info {
  display: flex;
  font-family: Montserrat;
  font-size: 18px;
}

.project-info.right {
  justify-content: end;
}

.project-link {
  display: flex;
  width: 100%;
  justify-content: center;
}

.project-detailed {
  font-family: Montserrat;
  font-size: 16px;
  position: relative;
  width: 100px;
  transition: all 0.3s ease-out;

  &:after {
    content: '';
    width: 15px;
    height: 2px;
    border-radius: 1px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: $black;
    transition: all 0.3s ease-out;
  }

  &:hover {
    color: $green;
    &:after {
      width: 100%;
      background-color: $green;
    }
  }
}

@media screen and (max-width: 800px) {
  .project,
  .project.reverse {
    display: flex;
    flex-direction: column;
  }

  .project-item {
    width: 100%;
    gap: 10px;
  }

  .project-title {
    font-size: 28px;
  }

  .project-year {
    font-family: Montserrat;
    font-size: 24px;
  }

  .project-title.right,
  .project-year.right {
    text-align: left;
  }

  .project-info {
    display: none;
  }

  .text-info {
    display: flex;
    flex-direction: column;
    order: 2;
  }
}
