@import '../../vars.scss';

.burger-menu {
  display: none;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
}

.burger-menu.active {
  background-image: linear-gradient(
    to bottom,
    rgba(223, 223, 223, 1) 0%,
    rgba(223, 223, 223, 0.95) 100%
  );
}

.close-btn {
  font-size: 2rem;
  background: transparent;
  border-color: transparent;
  color: $green;
  transition: $transition;
  cursor: pointer;
  margin-top: 0.2rem;
}
.close-btn:hover {
  color: $dark_green;
}
.logo {
  justify-self: center;
  width: 250px;
}
.links {
  margin-bottom: 2rem;
}
.links a {
  display: block;
  text-align: left;
  font-size: 1rem;
  text-transform: capitalize;
  padding: 1rem 1.5rem;
  color: $black;
  transition: $transition;
  letter-spacing: $spacing;
}

.links a:hover {
  padding: 1rem 1.5rem;
  padding-left: 2rem;
  background: $dark_green;
  color: $white;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $grey-bg;
  transition: $transition;
  transform: translate(100%);
  z-index: -1;
}
.show-sidebar {
  transform: translate(0);
  z-index: 999;
}
.cart-btn-wrapper {
  margin: 2rem auto;
}
@media screen and (max-width: 800px) {
  .sidebar {
    display: block;
  }

  .burger-menu {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    background-color: $header_gradient;
    padding: 15px 30px;
    z-index: 999;
    svg {
      width: 35px;
      height: 35px;
      cursor: pointer;
    }
  }
}
